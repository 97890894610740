import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import config from './config/config';
import './assets/styles/global.css'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueTheMask from 'vue-the-mask';

const app = createApp(App);

app.use(router);

app.config.globalProperties.$config = config;
app.use(VueSweetalert2);
app.use(VueTheMask);

app.mount('#app');
