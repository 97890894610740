<template>
  <MenuComponent />
  <div class="home-container">
    <h1 class="view-title">Avaliações Imobiliárias</h1>
    <table class="report-table">
      <thead>
        <tr>
          <th>Nome do requerente</th>
          <th>CPF do requerente</th>
          <th>Matrícula do Imóvel</th>
          <th>Valor declarado</th>
          <th>Data da Criação</th>
          <th>Status</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="report in reports" :key="report.id">
          <td>{{ report.nome_requerente }}</td>
          <td>{{ report.cpf_requerente }}</td>
          <td>{{ report.matricula }}</td>
          <td>{{ formatCurrency(report.valor_avaliacao) }}</td>
          <td>{{ report.data_criacao }}</td>
          <td>{{ report.status }}</td>
          <td>
            <div class="icon-container">
              <i
                class="icon edit-icon"
                @click="openEditModal(report.id)"
                title="Editar"
              ></i>
              <i
                class="icon cpf-icon"
                @click="openEditImoveis(report.id)"
                title="Editar imóveis"
              ></i>
              <i
                class="icon valor-icon"
                @click="openValorModal(report.id)"
                title="Valor arbitrado"
              ></i>

              <i
                class="icon delet-icon"
                @click="confirmDelete(report.id)"
                title="Deletar"
              ></i>
              <i
                class="icon custom-icon"
                @click="previewData(report.id)"
                title="Imprimir"
              ></i>
              <i
                class="icon salvar-icon"
                title="Status"
                @click="confirmStatusChange(report.id)"
              ></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div id="loading"></div>
    <div>
      <ModalEditarLaudo
        v-if="selectedLaudoId !== null && isLaudoModalVisible"
        :reportId="selectedLaudoId"
        @laudoAtualizado="fetchReports"
        @close="isLaudoModalVisible = false"
      />
      <ModalEditarImoveis
        v-if="selectedImoveisId !== null && isImoveisModalVisible"
        :report-id="selectedImoveisId"
        @close="isImoveisModalVisible = false"
      />
    </div>
    <ModalValorDeclarado
      v-if="showValorModal"
      :reportId="valorModalId"
      @valorAtualizado="fetchReports"
      @close="closeValorModal"
    />
    <div v-if="showAlert" class="custom-alert">
      <div class="alert-header">
        <span class="alert-title">Confirmação</span>
        <i class="pi pi-times close-icon" @click="closeAlert"></i>
      </div>
      <div class="alert-content">
        <p>Tem certeza que deseja deletar este laudo?</p>
        <div class="alert-buttons">
          <button
            class="confirm-button"
            @click="deleteReport(selectedReportsId)"
          >
            Confirmar
          </button>
          <button class="cancel-button" @click="closeAlert">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import ModalValorDeclarado from "./components/ModalValorDeclarado.vue";
import ModalEditarLaudo from "./components/ModalEditarLaudo.vue";
import ModalEditarImoveis from "./components/ModalEditarImoveis.vue";
import MenuComponent from "@/components/layoutComponent.vue/MenuComponent.vue";
import Swal from "sweetalert2";

export default {
  components: {
    ModalEditarLaudo,
    ModalValorDeclarado,
    MenuComponent,
    ModalEditarImoveis,
  },
  data() {
    return {
      reports: [],
      selectedLaudoId: null,
      selectedImoveisId: null,
      isLaudoModalVisible: false,
      isImoveisModalVisible: false,
      selectedReportId: null,
      selectedReportsId: null,
      isEditModalVisible: false,
      showValorModal: false,
      valorModalId: null,
      showAlert: false,
      loading: false,
      activeTab: "form",
      formData: {},
      laudo: {
        valorAvaliacao: "",
        valorM2: "",
        area_m2: "",
        itensSelecionados: [],
      },
      images: {
        fachada: "",
        localizacao: "",
      },
      fiscalId: null,
      userName: "",
    };
  },
  created() {
    this.fiscalId = sessionStorage.getItem("fiscal_id");

    if (this.fiscalId) {
      this.fetchReports();
    } else {
      console.error("fiscal_id não encontrado no sessionStorage");
    }
  },
  methods: {
    async fetchReports() {
      try {
        const res = await fetch(
          `${this.$config.apiBaseUrl}/api/laudos/fiscal/${this.fiscalId}`
        );
        if (!res.ok) throw new Error("Erro ao buscar relatórios");
        const data = await res.json();
        this.reports = data;
      } catch (error) {
        console.error("Erro ao buscar relatórios:", error);
      } finally {
        this.toggleLoading("none");
      }
    },
    getUserName() {
      this.userName = sessionStorage.getItem("userName") || "Guest";
    },
    async fetchReport(reportId) {
      try {
        const response = await axios.get(
          `${this.$config.apiBaseUrl}/api/laudo/${reportId}`
        );
        return response.data;
      } catch (error) {
        console.error("Erro ao obter os detalhes do relatório:", error);
        await Swal.fire({
          title: "Erro",
          text: "Houve um erro ao obter os detalhes do relatório. Por favor, tente novamente.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return null;
      }
    },

    async handleFinalizedReport() {
      await Swal.fire({
        title: "Relatório Finalizado",
        text: "O relatório está finalizado e não pode ser editado.",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok!",
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton) {
            confirmButton.style.cssText = `
            background-color: #FF9364 !important; 
            color: #FAFAFA !important; 
            border: none !important; 
            border-radius: 4px !important;  
            padding: 12px 24px !important;  
            font-size: 16px !important; 
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
            width: auto !important; 
            height: auto !important;  
            display: inline-block; 
            box-sizing: border-box;
          `;
          }
        },
      });
    },

    async openValorModal(reportId) {
      const report = await this.fetchReport(reportId);
      if (!report) return;

      if (report.status === "Finalizado") {
        await this.handleFinalizedReport();
      } else {
        this.valorModalId = null;
        this.$nextTick(() => {
          this.valorModalId = reportId;
          this.showValorModal = true;
        });
      }
    },

    closeValorModal() {
      this.showValorModal = false;
      window.location.reload();
    },

    async openEditModal(reportId) {
      const report = await this.fetchReport(reportId);
      if (!report) return;

      if (report.status === "Finalizado") {
        await this.handleFinalizedReport();
      } else {
        this.toggleModal("Laudo", reportId);
      }
    },

    async openEditImoveis(reportId) {
      const report = await this.fetchReport(reportId);
      if (!report) return;

      if (report.status === "Finalizado") {
        await this.handleFinalizedReport();
      } else {
        this.toggleModal("Imoveis", reportId);
      }
    },

    toggleModal(modalType, reportId) {
      const modalVisibleKey = `is${modalType}ModalVisible`;
      const selectedIdKey = `selected${modalType}Id`;

      this[modalVisibleKey] = false;
      this[selectedIdKey] = null;

      if (this[selectedIdKey] === reportId) {
        this[selectedIdKey] = null;
        this[modalVisibleKey] = false;
      } else {
        this[selectedIdKey] = reportId;
        this[modalVisibleKey] = true;
      }
    },
    async confirmDelete(reportId) {
      const report = await this.fetchReport(reportId);
      if (!report) return;

      if (report.status === "Finalizado") {
        await this.handleFinalizedReport();
      } else {
        this.selectedReportsId = reportId;
        const result = await Swal.fire({
          title: "Atenção",
          text: "Tem certeza que deseja deletar este laudo? Essa ação não pode ser desfeita.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Deletar",
          cancelButtonText: "Cancelar",
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            const cancelButton = Swal.getCancelButton();

            if (confirmButton) {
              confirmButton.style.cssText = `
                  background-color: #FF9364 !important; 
                  color: #FAFAFA !important; 
                  border: none !important; 
                  border-radius: 4px !important;  
                  padding: 10px 20px !important;  
                  font-size: 16px !important; 
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                  width: 190px !important; 
                  height: 46px !important;  
                `;
            }

            if (cancelButton) {
              cancelButton.style.cssText = `
                  background-color: #F4F4F5 !important; 
                  color: #18181B !important; 
                  border: none !important; 
                  border-radius: 4px !important;  
                  padding: 10px 20px !important;  
                  font-size: 16px !important; 
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                  width: 190px !important; 
                  height: 46px !important;  
                `;
            }
          },
        });

        if (result.isConfirmed) {
          this.deleteReport(this.selectedReportsId);
        }
      }
    },

    async deleteReport(reportId) {
      try {
        const response = await axios.delete(
          `${this.$config.apiBaseUrl}/api/laudo/${reportId}`
        );

        if (response.status === 200) {
          console.log("Laudo deletado com sucesso:", response.data);
          await this.fetchReports();
          Swal.fire({
            title: "Deletado!",
            text: "O relatório foi excluído com sucesso.",
            icon: "success",
            didOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              if (confirmButton) {
                confirmButton.style.cssText = `
                  background-color: #136E66 !important; 
                  color: #FAFAFA !important; 
                  border: none !important; 
                  border-radius: 4px !important;  
                  padding: 10px 20px !important;  
                  font-size: 16px !important; 
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                  width: 190px !important; 
                  height: 46px !important;  
                `;
              }
            },
          });
        } else {
          throw new Error("Erro ao deletar o relatório");
        }
      } catch (error) {
        console.error("Erro ao deletar laudo:", error);
        Swal.fire("Erro!", "Ocorreu um erro ao excluir o relatório.", "error");
      } finally {
        this.closeAlert();
      }
    },
      formatCurrency(value) {
    if (value === null || value === undefined || isNaN(value)) {
      return 'R$ 0,00'; 
    }

    const strValue = String(value);

    let number = strValue.replace(/\D/g, '');
    number = number.padStart(3, '0');

    const integerPart = number.slice(0, number.length - 2);
    const decimalPart = number.slice(-2);
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `R$ ${formattedIntegerPart},${decimalPart}`;
  },
    closeAlert() {
      this.showAlert = false;
      this.selectedReportsId = null;
    },
    toggleLoading(option) {
      this.loading = !this.loading;
    },
    async loadReportData(reportId) {
      try {
        const response = await fetch(
          `${this.$config.apiBaseUrl}/api/laudo/${reportId}`
        );
        const data = await response.json();

        if (data.userEmail) {
          sessionStorage.setItem("userEmail", data.userEmail);
        } else {
          console.warn("Email do usuário não disponível");
        }

        if (data.userName) {
          sessionStorage.setItem("userName", data.userName);
        } else {
          console.warn("Nome do usuário não disponível");
        }

        this.laudo = {
          valorAvaliacao: data.valor_avaliacao,
          valorM2: data.valor_m2,
          area_m2: data.area_m2,
          itensSelecionados: data.itens_selecionados,
          valor_arbitrado: data.valor_arbitrado,
          nome_requerente: data.nome_requerente,
          cpf_requerente: data.cpf_requerente,
          userEmail: sessionStorage.getItem("userEmail"),
          userName: sessionStorage.getItem("userName"),
          userCpf: sessionStorage.getItem("userCpf"),
        };
        this.formData = {
          nome_requerente: data.nome_requerente,
          cpf_requerente: data.cpf_requerente,
          endereco: data.endereco,
          complemento: data.complemento,
          matricula: data.matricula,
          empreendimento: data.empreendimento,
          uso: data.uso,
          tipologia: data.tipologia,
          condominio: data.condominio,
          andar_pavimento: data.andar_pavimento,
          idade_imovel: data.idade_imovel,
          estado_conservacao: data.estado_conservacao,
          total_dormitorios: data.total_dormitorios,
          total_bwc: data.total_bwc,
          vagas_garagem: data.vagas_garagem,
          valor_avaliacao: data.valor_avaliacao,
          area_m2: data.area_m2,
          valor_m2: data.valor_m2,
          observacoes_adicionais: data.observacoes_adicionais,
          fundamento_legal: data.fundamento_legal,
        };
      } catch (error) {
        console.error("Erro ao carregar os dados do laudo:", error);
      }
    },
    confirmStatusChange(reportId) {
      this.reportIdToUpdateStatus = reportId;

      Swal.fire({
        title: "Tem certeza?",
        text: "Tem certeza que deseja mudar o status dessa avaliação para finalizado? Essa ação não pode ser desfeita.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Finalizar",
        cancelButtonText: "Cancelar",
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          const cancelButton = Swal.getCancelButton();

          if (confirmButton) {
            confirmButton.style.cssText = `
              background-color: #FF9364 !important; 
              color: #FAFAFA !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 10px 20px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: 190px !important; 
              height: 46px !important;  
              
            `;
          }

          if (cancelButton) {
            cancelButton.style.cssText = `
              background-color: #F4F4F5 !important; 
              color: #18181B !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 10px 20px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: 190px !important; 
              height: 46px !important;  
            `;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatusToFinalized(this.reportIdToUpdateStatus);
        }
      });
    },
    async changeStatusToFinalized(reportId) {
      try {
        const response = await axios.post(
          `${this.$config.apiBaseUrl}/api/laudo/concluir/${reportId}`
        );

        if (response.status === 200) {
          if (response.data.message === "Laudo já está finalizado.") {
            console.log("Laudo já está finalizado:", response.data);
            Swal.fire({
              title: "Já Finalizado!",
              text: "O laudo já está marcado como finalizado.",
              icon: "info",
              didOpen: () => {
                const confirmButton = Swal.getConfirmButton();
                if (confirmButton) {
                  confirmButton.style.cssText = `
                      background-color: #136E66 !important; 
                      color: #FAFAFA !important; 
                      border: none !important; 
                      border-radius: 4px !important;  
                      padding: 10px 20px !important;  
                      font-size: 16px !important; 
                      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                      width: 190px !important; 
                      height: 46px !important;  
                    `;
                }
              },
            });
          } else {
            console.log(
              "Status do laudo atualizado com sucesso:",
              response.data
            );
            await this.fetchReports();
            Swal.fire({
              title: "Finalizado!",
              text: "O status da avaliação foi atualizado para finalizado.",
              icon: "success",
              didOpen: () => {
                const confirmButton = Swal.getConfirmButton();
                if (confirmButton) {
                  confirmButton.style.cssText = `
                      background-color: #136E66 !important; 
                      color: #FAFAFA !important; 
                      border: none !important; 
                      border-radius: 4px !important;  
                      padding: 10px 20px !important;  
                      font-size: 16px !important; 
                      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                      width: 190px !important; 
                      height: 46px !important;  
                    `;
                }
              },
            });
          }
        } else {
          throw new Error("Erro ao atualizar o status do laudo");
        }
      } catch (error) {
        console.error("Erro ao atualizar status do laudo:", error);
        Swal.fire("Erro!", "Ocorreu um erro ao atualizar o status.", "error");
      }
    },
    async previewData(reportId) {
      this.toggleLoading();
      try {
        await this.loadReportData(reportId);

        const reportResponse = await fetch(
          `${this.$config.apiBaseUrl}/api/imoveis/laudo/${reportId}`
        );
        const reportData = await reportResponse.json();

        const imagesResponse = await fetch(
          `${this.$config.apiBaseUrl}/api/laudo/${reportId}/imagens`
        );
        const imagesData = await imagesResponse.json();

        const imgFachada = imagesData.url_img_fachada
          ? `<img src="${imagesData.url_img_fachada}" style="margin-top: 10px; width: 350px; height: 250px; margin-bottom: 10px;" alt="Imagem de Fachada"/>`
          : `<p></p>`;

        const imgLocalizacao = imagesData.url_img_localizacao
          ? `<img src="${imagesData.url_img_localizacao}" style="margin-top: 10px; width: 350px; height: 250px; margin-bottom: 10px; " alt="Imagem de Localização"/>`
          : `<p></p>`;

        const formattedFormData = Object.entries(this.formData)
          .map(([key, value], index, array) => {
            const formattedKey = key.replace(/_/g, " ").toUpperCase();
            const displayKey = index === 15 ? "VALOR DECLARADO" : formattedKey;
            // Verificar se a linha é uma das últimas 5
            const isLastFive = index >= array.length - 5;
            return `<tr style="background-color: ${
              isLastFive ? "#ecfcfb" : "#fff"
            };">
              <th>${displayKey}</th>
              <td>${value}</td>
            </tr>`;
          })
          .join("");
        console.log(formattedFormData);

        const selectedItemsRows = reportData
          .map(
            (item, index) => `
        <div class="item-box">
          <h3>Amostra ${index + 1}</h3>
          <div>
            <strong>Link do Anuncio:</strong>
            <a href="${
              item.link_anuncio
            }" target="_blank" rel="noopener noreferrer">
              ${item.link_anuncio}
            </a>
          </div>
          <div><strong>Endereço:</strong> ${item.endereco}</div>
          <div><strong>Banheiros:</strong> ${item.total_bwc}</div>
          <div><strong>Quartos:</strong> ${item.total_dormitorios}</div>
          <div><strong>Garagem:</strong> ${item.vagas_garagem}</div>
          <div><strong>Preço:</strong> R$${item.valor}</div>
          <div><strong>Valor m²:</strong> R$${item.valor_m2}</div>
        </div>`
          )
          .join("");

        const printFrame = document.createElement("iframe");
        printFrame.style.position = "absolute";
        printFrame.style.width = "0px";
        printFrame.style.height = "0px";
        printFrame.style.border = "none";

        document.body.appendChild(printFrame);

        const printDocument =
          printFrame.contentDocument || printFrame.contentWindow.document;

        printDocument.open();
        printDocument.write(`
  <html>
      <style>
        /* Estilos gerais */
        body {
          font-family: Arial, sans-serif;
          padding: 20px;
        }
        h1 {
          color: #3B8070;
          margin: 0;
          font-family: times;
        }
        h3 {
          color: #136E66
          margin: 0;
          font-family: times;
        }
        .container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 20px;
        }
        .left-side, .right-side {
          width: 48%;
          box-sizing: border-box;
        }
        .left-side, .right-side {
          padding-right: 10px;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
          font-size: 0.8em;
        }

        td {
         
          text-align: right;
        }
          
        .item-box-container {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: space-between;
        }

        .item-box {
          flex: 1 1 calc(50% - 20px);
          max-width: 48%; 
          box-sizing: border-box;
          background-color: #f9f9f9;
        }

        @media (max-width: 768px) {
          .item-box {
            flex: 1 1 100%; 
          }
        }
        .button-container {
          margin-top: 20px;
        }
        .print-button {
          background-color: #136E66;
          color: white;
          border: none;
          padding: 8px 16px;
          cursor: pointer;
          border-radius: 4px;
        }
        .print-button:hover {
          background-color: #356f63;
        }
        .navbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #136E66;
          color: white;
          padding: 10px 20px;
        }
        .navbar-name {
          display: flex;
          align-items: center;
        }
        .logo {
          max-height: 50px;
          max-width: 100px;
        }
        .valor-final {
          background-color: #177168; 
          color: white;
          padding: 10px 15px; 
          border-radius: 12px; 
          margin: 20px auto; 
          font-size: 0.875em; 
          text-align: center; 
          width: 600px;
          box-sizing: border-box; 
        }


        .valor-final-title {
          color: white;
          margin: 0;
          font-size: 1.25em;
          font-weight: bold;
        }
        .sub-title {
          color: white;
          font-size: 0.875em;
          font-weight: bold;
          margin-top: -25px; 
          margin-bottom: 0; 
          line-height: 1.2;
        }

          
        .sub-value {
          color: white;
          font-size: 1.2em; 
          margin: 5px 0; 
        }

        .valor-final .row {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
        .valor-final .row > div {
          width: 45%;
          box-sizing: border-box;
        }
        .large-number {
          font-size: 40px;
          font-weight: bold;
          color: white;
          margin: 0px ;
        }
        .valor-final {
          font-size: 1.5em;
          font-weight: bold;
          color: white;
        }
        .line {
          border-top: 1px solid #000;
          margin-top: 20px;
        }
        .assinatura {
          margin-top: 20px;
        }
        .assinatura label {
          display: block;
          margin-top: 10px;
        }


        /* Estilos específicos para impressão */
        @media print {
          body {
            padding: 0;
          }
          .container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .left-side, .right-side {
            width: 48%;
            padding: 0;
          }
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            padding: 4px;
            font-size: 0.7em;
          }
          .item-box-container {
            gap: 20px;
          }
          .print-button {
            display: none;
          }
          .valor-final {
            background-color: #177168; 
            color: white; 
            padding: 1px 3px; 
            border-radius: 12px; 
            margin: 5px 0; 
            font-size: 0.5em; 
            text-align: center; 
            width: 100%; 
            box-sizing: border-box; 
          }
          .valor-final-title {
            color: white;
            margin: 0;
            font-size: 1.75em;
            font-weight: bold;
          }
                    .large-number {
          font-size: 40px;
          font-weight: bold;
          color: white;
          margin: 5px 0;
        }
          @page {
            margin: 0;
          }
          header, footer {
            display: none;
          }
          body {
            margin: 0;
          }
          .no-print {
            display: none;
          }
         h1 {
          color: #3B8070;
          margin: 0;
          font-family: times;
          font-size: 1.25em;
          text-align: left;
        }
        }
      </style>
  <header class="navbar">
    <div class="navbar-name">
      <img src="/Logo.png" class="logo">
    </div>
  </header>
    <body>
    <h1>Termo de Arbitramento para fins de ITBI</h1>
    <div class="container">
      <div class="left-side">
        <br/>
         <br/>
        ${imgFachada}
        <h3>Características do Imóvel</h3>
        <table id="dataTable">
          ${formattedFormData}
        </table>
        
      </div>

      <div class="right-side">
        <br/>
        <br/>
        ${imgLocalizacao}
        <br/>
        <br/>
        <br/>
        <br/>
        <div class="valor-final">
          <h2 class="valor-final-title">Valor Final Arbitrado</h2>
          <p class="large-number">R$${this.laudo.valor_arbitrado || 0}</p>
          <div class="row">
            <div>
              <h2 class="sub-title">Valor do m²</h2>
              <p class="sub-value">R$${(
                (this.laudo.valor_arbitrado || 0) / (this.laudo.area_m2 || 1)
              ).toFixed(2)} m²</p>
            </div>
            <div>
              <h2 class="sub-title">Área</h2>
              <p class="sub-value">R$${this.laudo.area_m2 || 0}m²</p>
            </div>
          </div>
        </div>
        <br><br>
        <div class="assinatura">
          <div>_________________________</div>
          <label> ${this.laudo.userName}</label>
          <label>Auditor</label>
          <label>CPF: ${this.laudo.userCpf}</label>
        </div>
      </div>
    </div>

    <!-- Quebra de página -->
    <div style="page-break-before: always;"></div>
    <header class="navbar">
      <div class="navbar-name">
        <img src="/Logo.png" class="logo">
      </div>
    </header>
    <h1>Elementos Amostrais Utilizados</h1>
    <div class="item-box-container">
      ${selectedItemsRows}
    </div>
    
  </body>
  </html>
    `);
        printDocument.close();

        printFrame.contentWindow.focus();
        printFrame.contentWindow.print();

        printFrame.addEventListener("afterprint", () => {
          document.body.removeChild(printFrame);
        });
      } catch (error) {
        console.error("Erro ao gerar a visualização do relatório:", error);
      } finally {
        this.toggleLoading("block");
      }
    },
  },
};
</script>

<style scoped>
#loading {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90px;
  height: 90px;
  background: url("../../assets/loading.gif") no-repeat center center;
  background-size: contain;
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

#loading.show {
  display: block;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.icon:hover {
  opacity: 0.8;
}

.edit-icon {
  --icon-url: url("@/assets/icons/create.png");
}

.delet-icon {
  --icon-url: url("@/assets/icons/delete.png");
  width: 18px; /* Ajuste a largura do ícone */
  height: 18px; /* Ajuste a altura do ícone */
  background-size: contain; /* Ajusta o tamanho da imagem de fundo para caber dentro do elemento */
  background-repeat: no-repeat; /* Evita a repetição da imagem de fundo */
  background-position: center; /* Centraliza a imagem de fundo */
  display: inline-block; /* Garante que o elemento se comporte como um bloco inline */
}

.salvar-icon {
  --icon-url: url("@/assets/icons/salvar.png");
}

.valor-icon {
  --icon-url: url("@/assets/icons/money.png");
}

.cpf-icon {
  --icon-url: url("@/assets/icons/Vector.png");
  width: 18px; /* Ajuste a largura do ícone */
  height: 18px; /* Ajuste a altura do ícone */
  background-size: contain; /* Ajusta o tamanho da imagem de fundo para caber dentro do elemento */
  background-repeat: no-repeat; /* Evita a repetição da imagem de fundo */
  background-position: center; /* Centraliza a imagem de fundo */
  display: inline-block; /* Garante que o elemento se comporte como um bloco inline */
}
.custom-icon {
  --icon-url: url("@/assets/icons/impressora.png");
  width: 18px; /* Ajuste a largura do ícone */
  height: 18px; /* Ajuste a altura do ícone */
  background-size: contain; /* Ajusta o tamanho da imagem de fundo para caber dentro do elemento */
  background-repeat: no-repeat; /* Evita a repetição da imagem de fundo */
  background-position: center; /* Centraliza a imagem de fundo */
  display: inline-block; /* Garante que o elemento se comporte como um bloco inline */
}

.icon {
  background-image: var(--icon-url);
}

.home-container {
  padding: 20px;
  background-image: url("/public/Fundotela.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
}

.view-title {
  text-align: left;
  margin-bottom: 20px;
  font-size: 250%;
  color: #136e66;
}

.report-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.report-table th,
.report-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.report-table th {
  background-color: #ecfcfb;
  color: black;
}

.report-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.report-table tr:hover {
  background-color: #ddd;
}

.report-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #ecfcfb;
  color: #136e66;
}

.action-icon {
  margin-right: 10px;
}

.edit-icon {
  color: green;
  cursor: pointer;
}

.delete-icon {
  color: red;
  cursor: pointer;
}

button {
  background-color: #3b8070;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #2c7463;
}

.custom-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: white;
  border: 1px solid #3b8070;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px;
}

.alert-header {
  background-color: #3b8070;
  color: white;
  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-title {
  font-weight: bold;
  font-size: 18px;
}

.close-icon {
  cursor: pointer;
}

.alert-content {
  padding: 10px;
}

.alert-content p {
  margin: 0;
}

.alert-buttons {
  text-align: right;
  margin-top: 10px;
}

.confirm-button,
.cancel-button {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 10px;
}

.confirm-button {
  background-color: #3b8070;
  color: white;
}

.cancel-button {
  background-color: #f2f2f2;
  color: #3b8070;
}
.report-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.report-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.report-table th,
.report-table td {
  padding: 10px;
  text-align: left;
}

.report-table td {
  border-bottom: 1px solid #ddd;
}

.report-table i {
  font-size: 1.2em;
  margin: 0 5px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.report-table i:nth-child(1) {
  color: #136e66;
}

.report-table i:nth-child(2) {
  color: #c82021;
}

.report-table i:nth-child(3) {
  color: #136e66;
}

.report-table i:hover {
  color: #000;
  transform: scale(1.2);
}

.report-table td .icon-container {
  display: flex;
  gap: 10px;
}

.report-table td {
  position: relative;
}

.icon-container i {
  font-size: 1.2em;
  color: #000;
}
</style>

