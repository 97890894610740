<template>
  <div class="modal-overlay" v-if="showModal">
    <div class="modal-contents">
      <i class="pi pi-times close-icon" @click="closeModal" title="Fechar"></i>
      <h1>Resumo da avaliação</h1>
      <form class="form-1">
        <div class="form-group type-group">
          <div class="form-item">
            <label for="nome_requerente">Nome do requerente</label>
            <p id="nome_requerente">{{ form.nome_requerente }}</p>
          </div>
          <div class="form-item">
            <label for="cpf">CPF do requerente</label>
            <p id="cpf">{{ form.cpf_requerente }}</p>
          </div>
          <div class="form-item">
            <label for="endereco">Endereço</label>
            <p id="endereco">{{ form.endereco }}</p>
          </div>
          <div class="form-item">
            <label for="complemento">Complemento</label>
            <p id="complemento">{{ form.complemento }}</p>
          </div>
          <div class="form-item">
            <label for="matricula">Matrícula do Imóvel</label>
            <p id="matricula">{{ form.matricula }}</p>
          </div>
          <div class="form-item">
            <label for="empreendimento">Empreendimento</label>
            <p id="empreendimento">{{ form.empreendimento }}</p>
          </div>
          <div class="form-item">
            <label for="uso">Uso</label>
            <p id="uso">{{ form.uso }}</p>
          </div>
          <div class="form-item">
            <label for="tipologia">Tipologia</label>
            <p id="tipologia">{{ form.tipologia }}</p>
          </div>
          <div class="form-item">
            <label for="condominio">Condomínio</label>
            <p id="condominio">{{ form.condominio }}</p>
          </div>
        </div>

        <div class="form-group details-group">
          <div class="form-item">
            <label for="andar_pavimento">Andar/Pavimento do Imóvel</label>
            <p id="andar_pavimento">{{ form.andar_pavimento }}</p>
          </div>
          <div class="form-item">
            <label for="idade_imovel">Idade do Imóvel</label>
            <p id="idade_imovel">{{ form.idade_imovel }}</p>
          </div>
          <div class="form-item">
            <label for="estado_conservacao">Conservação</label>
            <p id="estado_conservacao">{{ form.estado_conservacao }}</p>
          </div>
          <div class="form-item">
            <label for="total_dormitorios">Dormitórios</label>
            <p id="total_dormitorios">{{ form.total_dormitorios }}</p>
          </div>
          <div class="form-item">
            <label for="total_bwc">Total BWC</label>
            <p id="total_bwc">{{ form.total_bwc }}</p>
          </div>
          <div class="form-item">
            <label for="vagas_garagem">Vagas Garagem</label>
            <p id="vagas_garagem">{{ form.vagas_garagem }}</p>
          </div>
          <div class="form-item">
            <label for="valor_avaliacao">Valor declarado</label>
            <p id="valor_avaliacao">{{ formatCurrency(form.valor_avaliacao) }}</p>
          </div>
          <div class="form-item">
            <label for="area_m2">Área m²</label>
            <p id="area_m2">{{ form.area_m2 }}</p>
          </div>
          <div class="form-item">
            <label for="valor_m2">Valor do m²</label>
            <p id="valor_m2">{{ formatCurrency(form.valor_m2) }}</p>
          </div>
        </div>
      </form>

      <h1>Imóveis amostrais vinculados</h1>
      <div v-if="loading" class="loading-indicator">
        <i class="pi pi-spinner pi-spin"></i> Carregando...
      </div>
      <div class="styled-tables" v-if="selectedItems.length">
        <div class="title">
          <h5 style="margin: 10px">Itens Selecionados</h5>
        </div>
        <table style="margin: 0 auto; width: 100%; text-align: center">
          <thead id="selectedItensHeader">
            <tr>
              <th class="itemHeader">Endereço</th>
              <th class="itemHeader">Área (m²)</th>
              <th class="itemHeader">Quartos</th>
              <th class="itemHeader">Banheiros</th>
              <th class="itemHeader">Vagas</th>
              <th class="itemHeader">Preço (R$)</th>
              <th class="itemHeader">Preço por (m²)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in selectedItems" :key="item.index">
              <td class="itemData">{{ item.endereco }}</td>
              <td class="itemData">{{ item.area_m2 || "" }}m²</td>
              <td class="itemData">{{ item.total_dormitorios }}</td>
              <td class="itemData">{{ item.total_bwc }}</td>
              <td class="itemData">{{ item.vagas_garagem }}</td>
              <td class="itemData"> R$ {{ item.valor }}</td>
              <td class="itemData">
                R$ {{ calcularPrecoPorM2(item.area_m2, item.valor) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr class="linha" />

      <h1>Adicionar o Valor arbitrado para o imóvel</h1>
      <div v-if="loading" class="loading-indicator">
        <i class="pi pi-spinner pi-spin"></i> Carregando...
      </div>
      <form @submit.prevent="updateValue">
        <div class="additional-info-group">
          <div class="half-width">
            <span class="span" for="valor-declarado"
              >Valor arbitrado para o imóvel:</span
            >
            <input
              class="input"
              type="text"
              :value="formattedValorDeclarado"
              @input="handleInput"
              id="valor-declarado"
              required
            />
          </div>
        </div>
        <br />
        <div class="textarea-grid">
          <div class="info-textarea">
            <span class="span" for="observacoes_adicionais"
              >Observações Adicionais</span
            >
            <textarea
              id="observacoes_adicionais"
              v-model="form.observacoes_adicionais"
              maxlength="500"
            ></textarea>
          </div>
          <div class="info-textarea">
            <span class="span" for="fundamento_legal">Fundamento Legal</span>
            <textarea
              id="fundamento_legal"
              v-model="form.fundamento_legal"
              maxlength="100"
            ></textarea>
          </div>
        </div>
        <div class="button-container" style="margin-top: 0px !important;">
          <button type="button" class="cancel-buttons" @click="closeModal">
            Cancelar
          </button>
          <button type="submit" class="submit-buttons" :disabled="loading">
            Salvar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  props: {
    reportId: Number,
  },
  data() {
    return {
      showModal: false,
      valorDeclarado: "",
      originalValorDeclarado: "",
      loading: false,
      successMessage: "",
      errorMessage: "",
      form: {
        observacoes_adicionais: "",
        fundamento_legal: "",
      },
      laudo: {
        valorAvaliacao: "",
        valorM2: "",
        area_m2: "",
        itensSelecionados: [],
      },
      originalForm: {
        observacoes_adicionais: "",
        fundamento_legal: "",
      },
      selectedItems: [],
      saved: false,
      laudoID: null,
    };
  },
  watch: {
    reportId(newVal) {
      if (newVal) {
        this.loadReportData(newVal);
        this.loadReportDatas(newVal);
        this.showModal = true;
      }
    },
  },
  computed: {
    formattedValorDeclarado() {
      return this.formatCurrency(this.valorDeclarado);
    },
  },
  methods: {
    formatCurrency(value) {
      const strValue = String(value || '').replace(/^0+/, ''); 
      let number = strValue.replace(/\D/g, '');
      if (number.length === 0) return "R$ 0,00"; 
      number = number.padStart(3, '0');
      const integerPart = number.slice(0, number.length - 2);
      const decimalPart = number.slice(-2);
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return `R$ ${formattedIntegerPart},${decimalPart}`;
    },
    handleInput(event) {
      const rawValue = event.target.value.replace(/\D/g, ''); 
      this.valorDeclarado = rawValue;
  },
    calcularPrecoPorM2(area, price) {
      const areaNum = parseFloat(area);
      const priceNum = parseFloat(price);

      if (!isNaN(areaNum) && !isNaN(priceNum) && areaNum > 0) {
        return ` ${(priceNum / areaNum).toFixed(2).replace(".", ",")}`;
      }
      return "R$ 0,00";
    },
    async loadReportData(reportId) {
      this.loading = true;
      try {
        const response = await axios.get(
          `${this.$config.apiBaseUrl}/api/laudo/${reportId}`
        );
        if (response.status !== 200) {
          throw new Error("Erro ao buscar os dados do relatório");
        }

        const laudoData = response.data;

        // Preenchendo todos os campos do formulário com valores dos dados recebidos

        this.form = {
          endereco: laudoData.endereco || "",
          nome_requerente: laudoData.nome_requerente || "",
          cpf_requerente: laudoData.cpf_requerente || "",
          itensSelecionados: laudoData.itens_selecionados || [],
          complemento: laudoData.complemento || "",
          matricula: laudoData.matricula || "",
          empreendimento: laudoData.empreendimento || "",
          uso: laudoData.uso || "",
          tipologia: laudoData.tipologia || "",
          condominio: laudoData.condominio || "",
          andar_pavimento: laudoData.andar_pavimento || "",
          idade_imovel: laudoData.idade_imovel || "",
          estado_conservacao: laudoData.estado_conservacao || "",
          total_dormitorios: laudoData.total_dormitorios || "",
          total_bwc: laudoData.total_bwc || "",
          vagas_garagem: laudoData.vagas_garagem || "",
          valor_avaliacao: laudoData.valor_avaliacao || "",
          area_m2: laudoData.area_m2 || "",
          valor_m2: laudoData.valor_m2 || "",
          observacoes_adicionais: laudoData.observacoes_adicionais || "",
          fundamento_legal: laudoData.fundamento_legal || "",
          fiscal_id: laudoData.fiscal_id || null,
        };

        // Armazenando os dados originais para comparação futura
        this.originalForm = { ...this.form };

        // Atribuindo valores adicionais
        this.valorDeclarado = laudoData.valor_arbitrado || "";
        this.originalValorDeclarado = laudoData.valor_arbitrado || "";
      } catch (error) {
        console.error("Erro ao carregar os dados do relatório:", error);
        this.errorMessage = "Erro ao carregar os dados do relatório";
      } finally {
        this.loading = false;
      }
    },
    async loadReportDatas(reportId) {
      this.loading = true;
      try {
        const response = await fetch(
          `${this.$config.apiBaseUrl}/api/imoveis/laudo/${reportId}`
        );

        // Log the response status and URL for debugging
        console.log("Response Status:", response.status);
        console.log(
          "Requested URL:",
          `${this.$config.apiBaseUrl}/api/imoveis/laudo/${reportId}`
        );

        if (!response.ok) {
          throw new Error("Erro ao buscar os dados do imóvel");
        }

        const data = await response.json();

        // Log the fetched data for debugging
        console.log("Fetched Data:", data);

        this.selectedItems = data; // Atualiza a lista de itens selecionados
      } catch (error) {
        console.error("Erro ao carregar os dados do imóvel:", error);
        this.errorMessage = "Erro ao carregar os dados do imóvel";
      } finally {
        this.loading = false;
      }
    },

    closeModal() {
      this.showModal = false;
      this.successMessage = "";
      this.errorMessage = "";
      this.valorDeclarado = "";
      this.form.observacoes_adicionais = "";
      this.form.fundamento_legal = "";
    },
    formatValue(value) {
      return value.replace(/\./g, "").replace(",", ".");
    },
    async updateValue() {
      this.loading = true;
      try {
        const changes = {};
        if (this.valorDeclarado !== this.originalValorDeclarado) {
          changes.valor_arbitrado = this.formatValue(this.valorDeclarado);
        }

        if (
          this.form.observacoes_adicionais !==
          this.originalForm.observacoes_adicionais
        ) {
          changes.observacoes_adicionais = this.form.observacoes_adicionais;
        }

        if (this.form.fundamento_legal !== this.originalForm.fundamento_legal) {
          changes.fundamento_legal = this.form.fundamento_legal;
        }

        if (Object.keys(changes).length > 0) {
          const response = await axios.put(
            `${this.$config.apiBaseUrl}/api/laudo/${this.reportId}`,
            changes
          );
          if (response.status !== 200) {
            throw new Error("Erro ao salvar o valor arbitrado");
          }
          this.successMessage = "Dados salvos com sucesso";
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: this.successMessage,
          });
          this.$emit("valorAtualizado");
          this.closeModal();
        } else {
          Swal.fire({
            icon: "info",
            title: "Sem alterações",
            text: "Nenhuma alteração detectada para salvar.",
          });
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = "Erro ao salvar os dados";
        }
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: this.errorMessage,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
