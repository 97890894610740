<template>
  <div class="modal-overlay" v-if="showModal">
    <div class="modal-content">
      <h1>Edição dos imóveis amostrais vinculados</h1>

      <table class="styled-table">
        <thead>
          <tr>
            <th>Endereço</th>
            <th>Área (m²)</th>
            <th>Quartos</th>
            <th>Banheiros</th>
            <th>Vagas</th>
            <th>Preço</th>
            <th>Preço por m²</th>
            <th>Link</th>
            <th>ação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in formData" :key="item.id">
            <td>{{ item.endereco }}</td>
            <td>{{ item.area_m2 }}</td>
            <td>{{ item.total_dormitorios }}</td>
            <td>{{ item.total_bwc }}</td>
            <td>{{ item.vagas_garagem }}</td>
            <td>R$ {{ item.valor.toLocaleString("pt-BR") }}</td>
            <td>R$ {{ item.valor_m2.toLocaleString("pt-BR") }}</td>
            <td>
              <a
                :href="item.link_anuncio"
                target="_blank"
                rel="noopener noreferrer"
              >
                Abrir link
              </a>
            </td>
            <td>
              <i
                class="icon delet-icon"
                @click="confirmDelete(item.id)"
                title="Deletar"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="button-container">
        <button
          type="button"
          class="cancel-button"
          @click="closeModal"
          :disabled="loading"
        >
          Cancelar
        </button>
        <button
          type="button"
          class="submit-button"
          @click="confirmDeleteAll"
          :disabled="loading"
        >
          Deletar todos
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "/src/views/consultaView/components/styleModal.css";
export default {
  props: ["reportId"],
  data() {
    return {
      showModal: false,
      loading: false,
      successMessage: "",
      errorMessage: "",
      formData: [],
    };
  },
  watch: {
    reportId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.loadReportData(newVal);
          this.showModal = true;
        }
      },
    },
  },
  methods: {
    async loadReportData(reportId) {
      this.loading = true;
      try {
        const response = await fetch(
          `${this.$config.apiBaseUrl}/api/imoveis/laudo/${reportId}`
        );
        if (!response.ok) {
          throw new Error("Erro ao buscar os dados do imóvel");
        }
        const data = await response.json();
        console.log("Dados retornados:", data);
        this.formData = data;
      } catch (error) {
        console.error("Erro ao carregar os dados do imóvel:", error);
        this.errorMessage = "Erro ao carregar os dados do imóvel";
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.showModal = false;
      window.location.reload();
    },
    async confirmDeleteAll() {
      const result = await Swal.fire({
        title: "Atenção",
        text: "Tem certeza que deseja deletar este imóvel amostral? Essa ação não pode ser desfeita.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Deletar Todos",
        didOpen: () => {
          const cancelButton = Swal.getCancelButton();
          const confirmButton = Swal.getConfirmButton();

          if (cancelButton) {
            cancelButton.style.cssText = `
            background-color: #F4F4F5 !important; 
            color: #18181B !important; 
            border: none !important; 
            border-radius: 4px !important;  
            padding: 10px 20px !important;  
            font-size: 16px !important; 
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
            width: 190px !important; 
            height: 46px !important;  
          `;
          }
          if (confirmButton) {
            confirmButton.style.cssText = `
            background-color: #FF9364 !important; 
            color: #FAFAFA !important; 
            border: none !important; 
            border-radius: 4px !important;  
            padding: 10px 20px !important;  
            font-size: 16px !important; 
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
            width: 190px !important; 
            height: 46px !important;  
          `;
          }
        },
      });

      if (result.isConfirmed) {
        await this.deleteAllReports();
      }
      if (result.isConfirmed) {
        await this.deleteID();
      }
    },
    async confirmDelete(reportId) {
      const result = await Swal.fire({
        title: "Atenção",
        text: "Tem certeza que deseja deletar este imóvel amostral? Essa ação não pode ser desfeita.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Deletar",
        didOpen: () => {
          const cancelButton = Swal.getCancelButton();
          const confirmButton = Swal.getConfirmButton();

          if (cancelButton) {
            cancelButton.style.cssText = `
              background-color: #F4F4F5 !important; 
              color: #18181B !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 10px 20px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: 190px !important; 
              height: 46px !important;  
            `;
          }
          if (confirmButton) {
            confirmButton.style.cssText = `
              background-color: #FF9364 !important; 
              color: #FAFAFA !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 10px 20px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: 190px !important; 
              height: 46px !important;  
            `;
          }
        },
      });

      if (result.isConfirmed) {
        await this.deleteID(reportId);
      }
    },

    async deleteAllReports() {
      this.loading = true;
      try {
        const response = await fetch(
          `${this.$config.apiBaseUrl}/api/imoveis/laudo/${this.reportId}`,
          {
            method: "DELETE",
          }
        );
        if (!response.ok) {
          throw new Error("Erro ao deletar todos os imóveis");
        }
        this.formData = [];
        this.successMessage = "Todos os imóveis foram deletados com sucesso";
        await Swal.fire({
          title: "Sucesso",
          text: this.successMessage,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error("Erro ao deletar todos os imóveis:", error);
        this.errorMessage = "Erro ao deletar todos os imóveis";

        await Swal.fire({
          title: "Erro",
          text: this.errorMessage,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } finally {
        this.loading = false;
        this.closeModal();
      }
    },
    async deleteID(reportId) {
      this.loading = true;
      try {
        const response = await fetch(
          `${this.$config.apiBaseUrl}/api/imoveis/${reportId}`,
          {
            method: "DELETE",
          }
        );
        if (!response.ok) {
          throw new Error("Erro ao deletar imóveis");
        }
        this.formData = this.formData.filter((item) => item.id !== reportId); 
        this.successMessage = "O imóvel foi deletado com sucesso";

        await Swal.fire({
          title: "Sucesso",
          text: this.successMessage,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error("Erro ao deletar imóveis:", error);
        this.errorMessage = "Erro ao deletar imóvel";

        await Swal.fire({
          title: "Erro",
          text: this.errorMessage,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } finally {
        this.loading = false;
        this.closeModal();
      }
    },
  },
};
</script>
